import styled from "styled-components";
import { Socials } from "./Socials";
import me from "./me.jpg";
import { Download } from "./Download";
import React from "react";
import Typewriter from "typewriter-effect";

const Home = () => {
  return (
    <div>
      <Container>
        <HomeContent>
          <Intro>
            <H3>Hello, It's me</H3>
            <H1>Thajanah Mailvaganam</H1>
            <H3>
              And I'm a{" "}
              <DeveloperSpan>
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .typeString("Backend Developer")
                      .pauseFor(1000)
                      .deleteAll()
                      .typeString("Frontend Developer")
                      .pauseFor(1000)
                      .deleteAll()
                      .typeString("Full-Stack Web Developer")
                      .start();
                  }}
                />
              </DeveloperSpan>{" "}
            </H3>
          </Intro>
          <SocialIcons>
            <Socials />
            <Download />
          </SocialIcons>
        </HomeContent>
        <RightSide>
          <Picture src={me} />
          <Quote>Creativity is intelligence having fun</Quote>
          <Quote>- Albert Einstein</Quote>
          <SmallScreenSocialIcons>
            <Socials />
          </SmallScreenSocialIcons>
        </RightSide>
      </Container>
    </div>
  );
};

export default Home;

const Container = styled.div`
  color: #000000;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 70px 10% 0;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const SocialIcons = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const H3 = styled.h3`
  font-size: 32px;
  font-weight: 700;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 0.7s;

  @keyframes slideBottom {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const H1 = styled.h1`
  font-size: 56px;
  font-weight: 700;
  margin: -3px 0;
`;

const HomeContent = styled.div`
  max-width: 600px;
  z-index: 9000;
  @media (max-width: 700px) {
    position: relative;
    top: 80px;
  }
`;

const DeveloperSpan = styled.span`
  font-size: 32px;
  font-weight: 700;
  color: #ff5f1f;
`;

const Picture = styled.img`
  max-width: 530px;
  margin-right: -20px;
  opacity: 0;
  animation: zoomIn 1s ease forwards;
  animation-delay: 2s;
  @keyframes zoomIn {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @media (max-width: 400px) {
    width: 450px;
    position: relative;
    top: -80px;
    margin-right: 22px;
  }
`;

const Intro = styled.div`
  margin-bottom: 30px;
`;

const Quote = styled.p`
  font-family: "Mrs Saint Delafield", cursive;
  font-family: "Satisfy", cursive;
  font-family: "Shalimar", cursive;
  font-size: 30px;
  position: relative;
  margin-bottom: 10px;
  bottom: 240px;
  z-index: 100;

  @media (max-width: 700px) {
    max-width: 290px;
  }
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SmallScreenSocialIcons = styled.div`
  display: none;
  @media (max-width: 700px) {
    display: block;
    position: relative;
    top: -70px;
  }
`;
